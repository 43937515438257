







































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component({
  components: {},
})
export default class SupplierLocationList extends Vue {
  private suppliers = []
  private mySupplierId = null

  private locationHeaders = []
  private locationTablePagination = {
    descending: true,
    page: 1,
    itemsPerPage: -1,
    sortBy: [],
  }

  private manageLocationCategoriesDialog = false
  private newLocationCategoryName = null

  private locationCategories = []
  private managedLocationCategories = []

  private e2eSearchType = []
  private e2eSites = []

  private canExposeExternalProductsAsInternal = false

  private categoriesDisabled = true

  private request = {
    orderId: null,
    mainOrderReference: null,
    otherOrderReference: null,
    mainOrderLineReference: null,
    otherOrderLineReference: null,
  }

  private response = {
    mainOrderReference: null,
    otherOrderReference: null,
    mainOrderLineReference: null,
    otherOrderLineReference: null,
  }

  private created() {
    this.locationHeaders = [
      {
        text: this.$t('c:supplier-warehouse-list:Warehouse'),
        value: 'name',
      },
      // enable this when you enable categories categoriesDisabled
      // {
      //   text: this.$t('c:supplier-warehouse-list:Category'),
      //   value: 'locationCategoryId',
      // },
      {
        text: this.$t('c:supplier-warehouse-list:Exclude from Eontyre search'),
        value: 'excludeFromEontyreSearch',
      },
      {
        text: this.$t('c:supplier-warehouse-list:E2E search'),
        value: 'e2eSearchType',
      },
      {
        text: this.$t('c:supplier-warehouse-list:E2E sites'),
        value: 'e2eSites',
      },
    ]

    this.$axios
      .get('/v3/suppliers/location/list/get_initial_data')
      .then((response) => {
        this.suppliers = response.data.data.suppliers
        this.mySupplierId = response.data.data.mySupplierId
        this.locationCategories = response.data.data.locationCategories.slice()
        this.managedLocationCategories = response.data.data.locationCategories.slice()
        this.e2eSearchType = response.data.data.e2eSearchType
        this.canExposeExternalProductsAsInternal = response.data.data.canExposeExternalProductsAsInternal

        this.e2eSites = response.data.data.e2eSites.map((s) => ({
          id: s.id,
          name: s.name + ' (p' + s.id + ')',
        }))
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
  }

  private toggleRemovalLocationCategory(index) {
    const modified = this.managedLocationCategories[index]
    modified.removed = !modified.removed
    Vue.set(this.managedLocationCategories, index, modified)
  }

  private addLocationCategory() {
    this.newLocationCategoryName = this.newLocationCategoryName.trim()
    if (this.newLocationCategoryName === null || this.newLocationCategoryName === '') {
      vxm.alert.error({
        content: this.$t('supplier.warehouse.writeACategoryName') as string,
        title: this.$t('c:common:Error') as string,
      })
      return
    }
    this.managedLocationCategories.push({ id: -1, name: this.newLocationCategoryName, new: true })
    this.newLocationCategoryName = null
  }

  private saveLocationCategories() {
    const data = {
      locationCategories: this.managedLocationCategories,
    }
    this.$axios
      .post('/v3/suppliers/location/list/save_categories', data)
      .then((response) => {
        this.locationCategories = response.data.data.locationCategories.slice()
        this.managedLocationCategories = response.data.data.locationCategories.slice()
        vxm.alert.success({
          content: this.$t('c:common:Successfully saved') as string,
          title: this.$t('c:common:Success') as string,
        })
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
  }

  private saveLocationCategoryConnections() {
    const data = {
      suppliers: this.suppliers,
    }
    this.$axios
      .post('/v3/suppliers/location/list/save_category_connections', data)
      .then(() => {
        vxm.alert.success({
          content: this.$t('c:common:Successfully saved') as string,
          title: this.$t('c:common:Success') as string,
        })
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
  }

  private preview() {
    this.$axios
      .post('/v4/site/suppliers/preview-reference', this.request)
      .then((response) => {
        this.response = response.data.data
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
  }
}
